import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    if (!window.hasPopStateListener) {
      window.addEventListener('popstate', () => {
        Rails.ajax({
          url: window.location.href,
          type: 'get',
          dataType: 'script',
        });
      });
      window.hasPopStateListener = true;
    }
  }

  loadMore() {
    const modelPath = this.element.getAttribute('data-model-filter-path');
    const currentUrl = new URL(window.location.href);
    const params = Object.fromEntries(currentUrl.searchParams);

    // Get current offset from the derivatives container
    const derivativesContainer = document.querySelector('[data-render-target="groups#derivatives"]');
    const currentOffset = derivativesContainer.children.length;

    // Add pagination params to existing URL params
    const requestParams = {
      ...params,
      limit: 20,
      offset: currentOffset,
    };

    const url = `${modelPath}/deal_rows?${new URLSearchParams(requestParams)}`;

    Rails.ajax({
      url,
      type: 'get',
      dataType: 'json',
      success: (result) => {
        // Append new content to the derivatives container
        derivativesContainer.innerHTML += result.html;

        // Handle load more button visibility
        const showLoadMoreButtonClass = 'g-derivatives__more--show';
        const loadMoreButton = document.querySelector('.g-derivatives__more');

        if (result.total_entries > derivativesContainer.children.length) {
          loadMoreButton.classList.add(showLoadMoreButtonClass);
        } else {
          loadMoreButton.classList.remove(showLoadMoreButtonClass);
        }
      },
    });
  }

  submit() {
    this.closeDrawer();
  }

  closeDrawer() {
    const drawer = document.querySelector('.drawer-open');
    if (drawer) {
      drawer.classList.remove('drawer-open');
      document.querySelector('body').classList.remove('has-drawer');
      document.querySelector('.drawer-overlay').style.display = 'none';
    }
  }
}

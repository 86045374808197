import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  openLightbox(event) {
    const lightboxElement = document.getElementById('alt-gallery-lightbox');
    const mainElement = document.getElementsByTagName('main');
    if (lightboxElement && mainElement.length > 0) {
      document.body.style.overflowY = 'hidden';
      mainElement[0].style.zIndex = '3';
      lightboxElement.style.display = 'flex';
      lightboxElement.dataset.index = event.target.dataset.index;
    }
  }

  closeLightbox(event) {
    const mainElement = document.getElementsByTagName('main');

    if (event.target !== event.currentTarget || mainElement.length === 0) {
      return;
    }

    document.body.style.overflowY = 'auto';
    mainElement[0].style.zIndex = '1';
    this.element.style.display = 'none';
  }
}
